import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Loadable from "@loadable/component"
import "../css/global.css"
const Herausforderung = Loadable(() =>
  import(/* webpackPrefetch: true */ "../components/Behoerden/herausforderung")
)
const Ziel = Loadable(() =>
  import(/* webpackPrefetch: true */ "../components/goal")
)
const Loesung = Loadable(() =>
  import(/* webpackPrefetch: true */ "../components/solution")
)
const Kontakt = Loadable(() =>
  import(/* webpackPrefetch: true */ "../components/Behoerden/kontakt")
)
const Startups = Loadable(() =>
  import(/* webpackPrefetch: true */ "../components/startups")
)

const Behoerden = () => (
  <Layout>
    <SEO
      title="Corona Navi | Für Behörden"
      description="Deine zentrale Anlaufstelle für alle Themen rund um Corona."
    />
    <Herausforderung />
    <Ziel />
    <Loesung />
    <Kontakt />
    <Startups />
  </Layout>
)

export default Behoerden
